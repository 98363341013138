<template>
  <input type="hidden" :name="name" :value="deviceId">
</template>
<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export default {
  props: {
    name: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      deviceId: null
    }
  },

  async mounted() {
    const fingerprint = await this.getDeviceId()
    this.deviceId = fingerprint.visitorId
  },

  methods: {
    getDeviceId: async() => {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      return result
    }
  }
}
</script>